import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/Dashboard/';

class AdminDashboardService {
  getOrderForecast() {
    return ApiService.get(`${API_URL}GetOrderForecast`);
  }
  getOrder() {
    return ApiService.get(`${API_URL}GetOrder`);
  }
  getUser() {
    return ApiService.get(`${API_URL}GetUser`);
  }
  getProvision() {
    return ApiService.get(`${API_URL}getProvision`);
  }
}

export default new AdminDashboardService();
