






























































































import Vue from 'vue';
import AdminDashboardService from '@/services/admin/AdminDashboardService';
import { MetaInfo } from 'vue-meta';

class AdminDashboardProvisionData {
  data = {
    provisionTotal: 0,
    provisionLast30Days: 0,
  };
  loading = true;
}

class AdminDashboardUserData {
  data = {
    customerCount: 0,
    ownerCount: 0,
  };
  loading = true;
}

class AdminDashboardOrderData {
  data = {
    orderCount: 0,
    successOrderCount: 0,
    orderHistory: [] as { date: string; count: number }[],
  };
  loading = true;
}

class AdminDashboardOrderForecastData {
  data = {
    orderCountLastMonth: 0,
    orderCountThisMonth: 0,
    orderCountThisMonthForecast: 0,
    orderCountNextMonthForecast: 0,
  };
  loading = true;
}

export default Vue.extend({
  data() {
    return {
      provisionData: new AdminDashboardProvisionData(),
      userData: new AdminDashboardUserData(),
      orderData: new AdminDashboardOrderData(),
      orderForecastData: new AdminDashboardOrderForecastData(),
    };
  },
  metaInfo(): MetaInfo {
    return { title: this.$t('pages.admin.AdminDashboardIndex.meta.title').toString() };
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  computed: {
    historyOrderChart(): { values: number[]; labels: string[] } {
      const values = [] as number[];
      const labels = [] as string[];
      if (this.orderData) {
        this.orderData.data.orderHistory.forEach((x) => {
          values.push(x.count);
          labels.push(x.date);
        });
      }
      return { values, labels };
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminDashboardIndex.breadcrumb.last' },
      ]);
    },
    loadData() {
      AdminDashboardService.getProvision().then((res) => {
        this.provisionData.loading = false;
        this.provisionData.data = res.value;
      });
      AdminDashboardService.getUser().then((res) => {
        this.userData.loading = false;
        this.userData.data = res.value;
      });
      AdminDashboardService.getOrder().then((res) => {
        this.orderData.loading = false;
        this.orderData.data = res.value;
      });
      AdminDashboardService.getOrderForecast().then((res) => {
        this.orderForecastData.loading = false;
        this.orderForecastData.data = res.value;
      });
      this.setBreadCrumb();
    },
  },
});
